import * as S from "./styles";
import { NextRouter, useRouter } from "next/router";
import { useCatalog } from "@whitelabel-webapp/catalog/shared/catalog-store";

export const PageNavigation: React.VFC = () => {
  const router = useRouter();
  const { catalog } = useCatalog();
  const currentPage = parseInt(router.query.page as string) || 1;
  const totalPages = catalog.metadata.pagination.pages;
  if (totalPages == 1) return null;

  const pagination = generatePagination(router, currentPage, totalPages);

  return (
    <S.Wrapper>
      {pagination.map((item) => {
        return (
          <S.NavPage
            key={item.link}
            onClick={() => router.push(item.link)}
            active={item.active}
          >
            {item.number}
          </S.NavPage>
        );
      })}
    </S.Wrapper>
  );
};

function generatePagination(
  router: NextRouter,
  currentPage: number,
  totalPages: number,
) {
  const pagination = [];

  const { pathname, query } = router;

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      const newQuery = { ...query, page: i };

      pagination.push({
        number: i,
        link: {
          pathname,
          query: newQuery,
        },
        active: i === currentPage,
      });
    }
  } else {
    let startPage, endPage;

    if (currentPage <= 3) {
      startPage = 1;
      endPage = 5;
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - 4;
      endPage = totalPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }

    for (let i = startPage; i <= endPage; i++) {
      const newQuery = { ...query, page: i };

      pagination.push({
        number: i,
        link: {
          pathname,
          query: newQuery,
        },
        active: i === currentPage,
      });
    }
  }

  return pagination;
}
