import { Flex } from "@ifood/pomodoro-components";
import styled, { DefaultTheme } from "styled-components";

type NavPageProps = {
  active: boolean;
};

const bellowDesktop = ({ theme }: { theme: DefaultTheme }) =>
  `max-width: ${theme.screens.l.min}`;

export const Wrapper = styled(Flex)`
  justify-content: center;
  gap: ${({ theme }) => theme.space.smaller};
  margin-top: ${({ theme }) => theme.space.largest};
  @media (${bellowDesktop}) {
    margin-top: ${({ theme }) => theme.space.larger};
  }
`;

export const NavPage = styled.div<NavPageProps>`
  width: 32px;
  height: 32px;

  --primaryColor: ${({ theme }) => theme.colors.primary};
  color: ${({ active }) => (active ? "white" : "var(--primaryColor)")};
  background-color: ${({ active }) =>
    active ? "var(--primaryColor)" : "white"};

  font-family: ${({ theme }) => theme.font.family.ifood.body};
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;

  cursor: pointer;
  transition: all 100ms;

  &:hover {
    filter: brightness(0.95);
  }
`;
